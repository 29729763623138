/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --accent: #be9913;
}

body,
html {
  color: rgba(254, 254, 254, 0.7);
  font-family: "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
  overflow-x: hidden;
  background-color: #101010;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: #f0f0f0;
  padding: 0;
  margin: 0 0 0px 0;
  line-height: 1.3em;
}

.cs-primary_font {
  font-family: "Helvetica", sans-serif;
}

.cs-secondary_font {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 56px;
  font-family: "Montserrat", sans-serif;
}

h2 {
  font-size: 42px;
  font-family: "Montserrat", sans-serif;
}

h3 {
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  margin-bottom: 10px;
}

ul {
  margin: 0 0 25px 0;
  padding-left: 20px;
  list-style: square outside none;
}

ol {
  padding-left: 20px;
  margin-bottom: 25px;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 15px;
  font-style: italic;
  font-size: 20px;
  line-height: 1.6em;
  margin: 0;
}

address {
  margin: 0 0 15px;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}

button {
  color: inherit;
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: none;
  color: var(--accent);
}

table {
  width: 100%;
  margin-bottom: 25px;
}
table th {
  font-weight: 600;
  color: rgba(254, 254, 254, 0.7);
}
table td,
table th {
  border-top: 1px solid #4d4d4d;
  padding: 11px 10px;
}

dl {
  margin-bottom: 25px;
}
dl dt {
  font-weight: 600;
}

b,
strong {
  font-weight: bold;
}

pre {
  color: rgba(254, 254, 254, 0.7);
  border: 1px solid #4d4d4d;
  font-size: 18px;
  padding: 25px;
  border-radius: 5px;
}

kbd {
  font-size: 100%;
  background-color: rgba(254, 254, 254, 0.7);
  border-radius: 5px;
}

@media screen and (max-width: 991px) {
  body,
  html {
    font-size: 16px;
    line-height: 1.6em;
  }
  h2 {
    font-size: 36px;
    margin-bottom: 0px;
  }
}
input,
textarea {
  color: #fefefe;
}

.technologyIcon1 {
  width: 150px;
  height: 120px;
}

.mglrft {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 480px) {
  h3 {
    font-size: 24px;
  }
}
.cs-light {
  font-weight: 300;
}

.cs-normal {
  font-weight: 400;
}

.cs-medium {
  font-weight: 500;
}

.cs-semi_bold {
  font-weight: 600;
}

.cs-bold {
  font-weight: 700;
}

.cs-extra_bold {
  font-weight: 800;
}

.cs-black {
  font-weight: 900;
}

.cs-radius_3 {
  border-radius: 3px;
}

.cs-radius_5 {
  border-radius: 5px;
}

.cs-radius_7 {
  border-radius: 7px;
}

.cs-radius_10 {
  border-radius: 10px;
}

.cs-radius_15 {
  border-radius: 15px;
}

.cs-line_height_1 {
  line-height: 1.2em;
}

.cs-line_height_2 {
  line-height: 1.25em;
}

.cs-line_height_3 {
  line-height: 1.3em;
}

.cs-line_height_4 {
  line-height: 1.4em;
}

.cs-line_height_5 {
  line-height: 1.5em;
}

.cs-line_height_6 {
  line-height: 1.6em;
}

.cs-line_height_7 {
  line-height: 1.7em;
}

.cs-font_14 {
  font-size: 14px;
}

.cs-font_16 {
  font-size: 16px;
}

.cs-font_18 {
  font-size: 18px;
}

.cs-font_20 {
  font-size: 20px;
}

.cs-font_22 {
  font-size: 22px;
}

.cs-font_28 {
  font-size: 28px;
}

.cs-font_30 {
  font-size: 28px;
}

.cs-font_38 {
  font-size: 38px;
}

.cs-font_42 {
  font-size: 42px;
}

.cs-font_50 {
  font-size: 40px;
}

.cs-mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cs-m0 {
  margin: 0;
}

hr {
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid #f2f1ff;
}

.cs-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.cs-vertical_middle {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.cs-vertical_middle_in {
  flex: none;
  width: 100%;
}

.cs-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs-white_color,
.cs-white_color_hover:hover {
  color: #fff;
}

.cs-primary_color {
  color: #fefefe;
}

.cs-ternary_color {
  color: #999696;
}

.cs-accent_color,
.cs-accent_color_hover:hover {
  color: var(--accent);
}

.cs-white_bg {
  background-color: #fff;
}

.cs-gray_bg {
  background-color: #181818;
}

.cs-accent_bg,
.cs-accent_bg_hover:hover {
  background-color: var(--accent);
}

.cs-primary_bg,
.cs-primary_bg_hover:hover {
  background-color: #fefefe;
}

.cs-gradient_bg_1 {
  background: linear-gradient(267.18deg, #161616 0%, #080808 100%);
}

.cs-text_btn {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.cs-text_btn svg {
  margin-left: 15px;
  font-size: 24px;
}
.cs-text_btn span {
  display: inline-block;
  position: relative;
}
.cs-text_btn span::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.6s;
  transition-timing-function: ease-out;
}
.cs-text_btn:hover span::before {
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.cs-text_btn.cs-type1 {
  flex-direction: row-reverse;
}
.cs-text_btn.cs-type1 svg {
  margin-left: 0px;
  margin-right: 15px;
  transform: rotate(180deg);
}
.cs-text_btn.cs-type1 span::before {
  left: initial;
  right: -100%;
  transform: scaleX(0) rotateY(180deg);
}
.cs-text_btn.cs-type1:hover span::before {
  transform: scaleX(1) rotateY(180deg);
}
.cs-text_btn.cs-type2 {
  flex-direction: row-reverse;
}
.cs-text_btn.cs-type2 svg {
  margin-left: 0;
  margin-right: 15px;
  color: var(--accent);
}

label {
  margin-bottom: 12px;
}

/* Start Accordion */
.cs-accordian_title {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 100;
  transition: all 0.3s ease;
}
@media (max-width: 991px) {
  .cs-accordian_title {
    font-size: 18px;
  }
}

.cs-accordian_body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}
.cs-accordian_body p {
  margin: 0;
}

.cs-accordian_body_in {
  line-height: 1.8em;
  padding-top: 15px;
}

.cs-accordian_head {
  padding: 2px 20px 2px 0;
  position: relative;
  cursor: pointer;
}
.cs-accordian_head:hover .cs-accordian_title {
  color: var(--accent);
}

.cs-accordian {
  border-bottom: 2px solid var(--accent);
  padding-bottom: 25px;
}
.cs-accordian:not(:last-child) {
  margin-bottom: 25px;
}
@media (max-width: 991px) {
  .cs-accordian {
    padding-bottom: 15px;
    border-width: 1px;
  }
  .cs-accordian:not(:last-child) {
    margin-bottom: 15px;
  }
}

.cs-accordian_toggle {
  display: flex;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 0;
  transition: all 0.3s ease;
}

.cs-accordian.active .cs-accordian_toggle {
  transform: rotate(180deg);
}
.cs-accordian.active .cs-accordian_head {
  pointer-events: none;
}
.cs-accordian.active .cs-accordian_body {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

/* End Accordion */
.cs-partner_logo_wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
}

.cs-partner_logo {
  padding: 20px;
}

.cs-page_heading.cs-style1 {
  height: 200px;
  padding: 101px 0 0px;
  position: relative;
}
.cs-page_heading.cs-style1::after {
  content: "";
  position: absolute;
  right: -30px;
  top: -30px;
  height: 151px;
  width: 151px;
  background: var(--accent);
  opacity: 0.8;
  filter: blur(125px);
}
.cs-page_heading.cs-style1 > * {
  position: relative;
  z-index: 2;
}
.cs-page_heading.cs-style1 .cs-page_heading_in {
  display: inline-block;
}
.cs-page_heading.cs-style1 .breadcrumb {
  justify-content: flex-end;
  margin-bottom: 0;
  color: #999696;
}
.cs-page_heading.cs-style1 .breadcrumb-item.active {
  color: #fff;
}
.cs-page_heading.cs-style1 .cs-page_title {
  margin-bottom: 18px;
}

.cs-google_map {
  height: 600px;
}
.cs-google_map iframe {
  height: 100%;
  display: block;
  border: none;
  width: 100%;
  filter: grayscale(100%) invert(90%) contrast(120%);
}
.cs-google_map.cs-type1 {
  height: 100%;
  min-height: 300px;
  width: 50vw;
}

.cs-shine_hover_1 {
  position: relative;
  overflow: hidden;
  width: 240px;
}
.cs-shine_hover_1::before {
  content: "";
  position: absolute;
  top: 0;
  left: -80%;
  z-index: 2;
  width: 50%;
  height: 100%;
  transform: skewX(-25deg);
  z-index: 1;
}
.cs-shine_hover_1:hover::before {
  animation: shine 1.3s;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
.cs-error_page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1380px) {
  .cs-section_heading.cs-style1 .cs-section_title {
    font-size: 42px;
  }
  .cs-font_50 {
    font-size: 42px;
  }
  .cs-shape_wrap_4 .cs-shape_4:nth-child(2) {
    height: 200px;
    width: 200px;
  }
  .cs-page_heading.cs-style1 {
    height: 200px;
  }
}
@media screen and (max-width: 1199px) {
  .cs-page_heading.cs-style1 {
    height: 200px;
  }
  .cs-half_screen {
    width: 100%;
  }
  .cs-video_block.cs-style1.cs-size1 {
    height: 450px;
  }
  .cs-case_study .cs-case_study_title {
    font-size: 60px;
  }
  .cs-masonry_4_col {
    -moz-column-count: 2;
         column-count: 2;
  }
  .cs-half_of_full_width {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .cs-section_heading.cs-style1 .cs-section_title {
    font-size: 30px;
  }
  .cs-section_heading.cs-style1 .cs-section_subtitle {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .cs-google_map {
    height: 400px;
  }
  .cs-column_reverse_lg {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 767px) {
  .cs-tabs.cs-style1 .cs-tabs_heading {
    display: block;
  }
  .cs-tabs.cs-style1 .cs-section_heading + .cs-tab_links {
    margin-top: 50px;
  }
  .cs-case_study {
    padding: 80px 12px;
  }
  .cs-case_study .cs-case_study_title {
    font-size: 40px;
    padding: 25px 25px;
  }
}
@media screen and (max-width: 575px) {
  .cs-partner_logo {
    padding: 5px;
  }
  .cs-partner_logo_wrap {
    margin: 0 -10px;
  }
  .cs-page_heading.cs-style1 .breadcrumb {
    justify-content: center;
  }
}
.mb-4 {
  margin-bottom: 1.5rem !important;
  padding: 0px !important;
}

/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
:root {
  --accent: #be9913;
}

.cs-site_header {
  position: relative;
  z-index: 101;
}
.cs-site_header .cs-btn.cs-style6 {
  font-size: 16px;
}

.cs-site-branding {
  display: inline-block;
  max-width: 180px;
}

.cs-site_header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  transition: all 0.4s ease;
}

.cs-site_header.cs-style1 .cs-main_header_in,
.cs-site_header.cs-style1 .cs-top_header_in {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 90px;
  position: relative;
}
.cs-site_header.cs-style1 .cs-main_header_right {
  display: flex;
  align-items: center;
  height: 100%;
}

.cs-site_header.cs-style1.cs-sticky-active {
  background-color: #fff;
  box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1);
}
.cs-site_header.cs-style1 .cs-icon_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid currentColor;
  border-radius: 50%;
  color: #fff;
}
.cs-site_header.cs-style1 .cs-icon_btn .cs-icon_btn_in {
  width: 18px;
  height: 16px;
  line-height: 0;
  cursor: pointer;
  position: relative;
  text-align: left;
  overflow: hidden;
}
.cs-site_header.cs-style1 .cs-icon_btn .cs-icon_btn_in span {
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 0;
  background-color: currentColor;
  color: inherit;
  vertical-align: top;
  transition: 0s ease-in-out;
  border-radius: 3px;
}
.cs-site_header.cs-style1 .cs-icon_btn .cs-icon_btn_in span:nth-child(1) {
  transform: translate(0, -7px);
  width: calc(100% - 4px);
}
.cs-site_header.cs-style1 .cs-icon_btn .cs-icon_btn_in span:nth-child(2) {
  transform: translate(0, 0);
  width: calc(100% - 4px);
}
.cs-site_header.cs-style1 .cs-icon_btn .cs-icon_btn_in span:nth-child(3) {
  transform: translate(0, 7px);
}
.cs-site_header.cs-style1 .cs-icon_btn .cs-icon_btn_in span:nth-child(4) {
  transform: translate(0, 14px);
}
.cs-site_header.cs-style1 .cs-icon_btn:hover .cs-icon_btn_in span {
  transition: 0.3s ease-in-out;
}
.cs-site_header.cs-style1 .cs-icon_btn:hover .cs-icon_btn_in span:nth-child(1) {
  transform: translate(0, 0);
}
.cs-site_header.cs-style1 .cs-icon_btn:hover .cs-icon_btn_in span:nth-child(2) {
  transform: translate(0, 7px);
  width: 100%;
}
.cs-site_header.cs-style1 .cs-icon_btn:hover .cs-icon_btn_in span:nth-child(3) {
  transform: translate(0, 14px);
}
.cs-site_header.cs-style1 .cs-icon_btn:hover .cs-icon_btn_in span:nth-child(4) {
  transform: translate(0, 21px);
}
.cs-site_header.cs-style1 .cs-action_box {
  display: flex;
  align-items: center;
}
.cs-site_header.cs-style1 .cs-action_box .cs-action_value {
  margin-left: 15px;
}
.cs-site_header.cs-style1 .cs-action_box > *:not(:last-child) {
  margin-right: 35px;
}
.cs-site_header.cs-style1 .cs-btn {
  padding: 13px 15px;
}

.cs-site_header_full_width .container {
  max-width: 100%;
  padding: 0 115px;
}

.cs-site_header_style1 {
  border-bottom: 1px solid #1a188d;
}
.cs-site_header_style1 .cs-main_header_right {
  display: flex;
  align-items: center;
}
.cs-site_header_style1 .cs-nav + .cs-header_toolbox.cs-center {
  margin-left: 35px;
}
.cs-site_header_style1 .cs-nav .cs-nav_list > li.current-menu-item > a:before {
  bottom: -4px;
  background-color: #fff;
}

.cs-side_header {
  position: fixed;
  top: 0;
  right: 0px;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
}
.cs-side_header .cs-side_header_overlay {
  background-color: rgba(22, 22, 22, 0.5);
  height: inherit;
  width: inherit;
  left: inherit;
  top: inherit;
  position: inherit;
  cursor: zoom-out;
  transition: all 0.4s ease;
  opacity: 0;
}
.cs-side_header .cs-side_header_in {
  width: 500px;
  height: 100vh;
  background-color: #181818;
  flex: none;
  margin-left: auto;
  overflow: auto;
  padding: 90px 50px 50px 50px;
  position: relative;
  z-index: 2;
  right: -500px;
  transition: all 0.4s ease;
}
.cs-side_header .cs-side_header_heading {
  font-size: 22px;
  line-height: 1.4em;
  margin: 0;
}
.cs-side_header .cs-side_header_title {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.cs-side_header .cs-side_header_box {
  padding: 35px 0;
}
.cs-side_header .cs-close {
  opacity: 0;
}
.cs-side_header.active {
  opacity: 1;
  visibility: visible;
}
.cs-side_header.active .cs-side_header_overlay,
.cs-side_header.active .cs-close {
  opacity: 1;
}
.cs-side_header.active .cs-side_header_in {
  right: 0;
}
.cs-side_header .cs-side_header_shape {
  height: 257px;
  width: 257px;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  z-index: -1;
  bottom: 0;
  right: 0;
}
.cs-side_header .cs-side_header_shape::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  right: -90px;
  bottom: -80px;
  overflow: hidden;
  background-color: #000;
  border-radius: 50%;
}

.cs-close {
  position: fixed;
  right: 30px;
  top: 30px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid currentColor;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  z-index: 2;
  z-index: 100;
}
.cs-close::before, .cs-close::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: currentColor;
  left: 50%;
  top: 50%;
  margin-left: -25%;
  margin-top: -1px;
  transform: rotate(-45deg);
}
.cs-close::before {
  transform: rotate(45deg);
}
.cs-close:hover {
  color: var(--accent);
  transform: scale(1.05);
}

@media (max-width: 2560px) and (min-width: 991px) {
  .cs-site_header.cs-style1 .cs-nav {
    display: flex;
  }
}
.cs-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cs-sticky_header {
  position: fixed !important;
  width: 100%;
  z-index: 999;
  transition: all 0.4s ease;
  background-color: hsla(0, 0%, 0%, 0.4);
}

.cs-sticky_header_active {
  top: 0px;
  background-color: hsla(0, 0%, 0%, 0.4);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}

.cs-site_branding {
  display: inline-block;
}
.cs-site_branding img {
  max-height: 40px;
}

@media screen and (min-width: 1200px) {
  .cs-main_header {
    position: relative;
  }
  .cs-main_header .container-fluid {
    padding-right: 40px;
    padding-left: 40px;
  }
  .cs-main_header_center,
  .cs-top_header_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .cs-site_header.cs-style1 .cs-main_header_center {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .cs-site_header.cs-style1 .cs-main_header_left {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cs-nav {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
    line-height: 1.6em;
    color: #fff;
  }
  .cs-nav .cs-nav_list {
    display: flex !important;
    flex-wrap: wrap;
    height: inherit;
  }
  .cs-nav .cs-nav_list > li {
    margin-right: 50px;
    height: inherit;
  }
  .cs-nav .cs-nav_list > li:last-child {
    margin-right: 0;
  }
  .cs-nav .cs-nav_list > li > a {
    padding: 10px 0;
    display: inline-flex;
    position: relative;
    height: inherit;
    align-items: center;
  }
  .cs-nav .cs-nav_list > li > ul {
    left: 0;
    top: calc(100% + 15px);
  }
  .cs-nav .cs-nav_list > li:hover > ul {
    top: 100%;
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
  }
  .cs-nav .cs-nav_list > li.menu-item-has-children > a {
    position: relative;
  }
  .cs-nav .cs-nav_list > li.menu-item-has-children > a::after {
    content: "";
    display: inline-block;
    height: 8px;
    width: 8px;
    border: 2px solid currentColor;
    transform: rotate(45deg);
    border-left: 0;
    border-top: 0;
    margin-left: 6px;
    position: relative;
    top: -2px;
    border-radius: 0px 0px 2px 0px;
  }
  .cs-nav .cs-nav_list li:not(.cs-mega-menu) {
    position: relative;
  }
  .cs-nav .cs-nav_list ul {
    width: 260px;
    background-color: #181818;
    position: absolute;
    box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
    border-top: 2px solid var(--accent);
    padding: 10px 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    border-radius: 0px 0px 5px 5px;
    transition: all 0.1s ease;
  }
  .cs-nav .cs-nav_list ul li:hover ul {
    top: 0px;
  }
  .cs-nav .cs-nav_list ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
  }
  .cs-nav .cs-nav_list ul a {
    font-size: 90%;
    display: block;
    line-height: inherit;
    padding: 10px 20px;
  }
  .cs-nav .cs-nav_list ul ul {
    top: 15px;
    left: 100%;
  }
  .cs-nav .cs-nav_list .active {
    color: var(--accent);
  }
  .cs-munu_toggle,
  .cs-munu_dropdown_toggle {
    display: none;
  }
  .cs-nav .cs-nav_list .cs-mega-wrapper {
    width: 1170px !important;
    left: 50%;
    transform: translateX(-50%);
    display: flex !important;
    position: absolute;
    padding: 0;
  }
  .cs-nav .cs-nav_list .cs-mega-wrapper > li {
    flex: 1;
    padding: 10px 0;
  }
  .cs-nav .cs-nav_list .cs-mega-wrapper > li:not(:last-child) {
    border-right: 1px solid #4d4d4d;
  }
  .cs-nav .cs-nav_list .cs-mega-wrapper > li > a {
    color: #be9913;
    font-weight: 600;
  }
  .cs-nav .cs-nav_list .cs-mega-wrapper > li > a:hover {
    background-color: transparent;
  }
  .cs-nav .cs-nav_list .cs-mega-wrapper > li ul {
    position: initial;
    border: none;
    padding: 0;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }
  .cs-nav .cs-nav_list .cs-mega-menu:hover .cs-mega-wrapper li ul {
    opacity: 1;
    visibility: visible;
  }
  .cs-nav .cs-nav_list > li ul:not(.cs-mega-wrapper) .menu-item-has-children > a {
    position: relative;
  }
}
@media screen and (max-width: 1360px) {
  .cs-site-branding {
    max-width: 190px;
  }
}
@media screen and (max-width: 1199px) {
  .cs-munu_dropdown_toggle {
    position: absolute;
    height: 30px;
    width: 30px;
    right: 15px;
    top: 5px;
  }
  .cs-munu_dropdown_toggle:before, .cs-munu_dropdown_toggle:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 10px;
    background-color: rgba(254, 254, 254, 0.7);
    transition: all 0.3s ease;
  }
  .cs-munu_dropdown_toggle:before {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .cs-munu_dropdown_toggle.active:before {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  .cs-nav .cs-nav_list {
    position: absolute;
    width: 100vw;
    left: -12px;
    background-color: #181818;
    padding: 10px 0;
    display: none;
    top: 100%;
    border-top: 1px solid rgba(77, 77, 77, 0.3215686275);
    border-bottom: 1px solid rgba(77, 77, 77, 0.3215686275);
    overflow: auto;
    max-height: calc(100vh - 80px);
    line-height: 1.6em;
  }
  .cs-nav .cs-nav_list ul {
    padding-left: 15px;
    display: none;
  }
  .cs-nav .cs-nav_list .cs-munu_dropdown_toggle.active + ul {
    display: block;
  }
  .cs-nav .cs-nav_list a {
    display: block;
    padding: 8px 20px;
  }
  .cs-nav .menu-item-has-children {
    position: relative;
  }
  /*Mobile Menu Button*/
  .cs-munu_toggle {
    display: inline-block;
    width: 24px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 27px;
    right: 30px;
  }
  .cs-munu_toggle span,
  .cs-munu_toggle span:before,
  .cs-munu_toggle span:after {
    width: 100%;
    height: 2px;
    background-color: currentColor;
    display: block;
  }
  .cs-munu_toggle span {
    margin: 0 auto;
    position: relative;
    top: 12px;
    transition-duration: 0s;
    transition-delay: 0.2s;
  }
  .cs-munu_toggle span:before {
    content: "";
    position: absolute;
    margin-top: -9px;
    transition-property: margin, transform;
    transition-duration: 0.2s;
    transition-delay: 0.2s, 0s;
  }
  .cs-munu_toggle span:after {
    content: "";
    position: absolute;
    margin-top: 9px;
    transition-property: margin, transform;
    transition-duration: 0.2s;
    transition-delay: 0.2s, 0s;
  }
  .cs-site_header.cs-style1 .cs-munu_toggle {
    top: 50%;
    right: 0px;
    margin-top: -13px;
    color: #fff;
  }
  .cs-toggle_active span {
    background-color: rgba(0, 0, 0, 0);
    transition-delay: 0.2s;
  }
  .cs-toggle_active span:before {
    margin-top: 0;
    transform: rotate(45deg);
    transition-delay: 0s, 0.2s;
  }
  .cs-toggle_active span:after {
    margin-top: 0;
    transform: rotate(-45deg);
    transition-delay: 0s, 0.2s;
  }
  .cs-header_toolbox {
    margin-right: 50px;
  }
  .cs-nav .cs-nav_list a {
    position: relative;
  }
  .cs-site_header.cs-style1 .cs-main_header_in {
    height: 80px;
  }
  .cs-hamburger span {
    height: 3px;
  }
  .cs-site_header .current-menu-item > a:before {
    display: none;
  }
  .cs-site_header.cs-style1.cs-type4 .cs-main_header_in {
    padding: 0;
    box-shadow: none;
    border-radius: 0px;
    margin-top: 0;
    background-color: transparent;
  }
  .cs-site_header.cs-style1 .cs-main_header_center .cs-site_branding {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  .cs-site_header.cs-style1 {
    top: 0;
  }
  .cs-toolbox {
    margin-right: 45px;
  }
  .cs-site_header.cs-style1 .cs-icon_btn {
    width: 38px;
    height: 38px;
  }
  .cs-toolbox,
  .cs-side_header {
    display: none;
  }
  .cs-site_header_full_width {
    max-width: 960%;
    padding: 0 15px;
  }
}
@media screen and (max-width: 991px) {
  .cs-site_header {
    max-width: 960px;
  }
  .cs-site_header.cs-style1 .cs-action_box > *:not(:last-child) {
    margin-right: 25px;
  }
  .cs-site_header.cs-style1 .cs-btn {
    padding: 8px;
  }
}
@media screen and (max-width: 575px) {
  .cs-site-branding {
    max-width: 150px;
  }
  .cs-hamburger_wrap .cs-hamburger {
    margin-right: 0;
  }
  .cs-site_header.cs-style1 .cs-btn span {
    display: none;
  }
  .cs-site_header.cs-style1 .cs-btn svg {
    margin-right: 0;
    width: 20px;
    height: 20px;
  }
}
.landingWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 15rem 0 0;
  position: relative;
}
.landingWrap .landRight {
  display: flex;
  z-index: 1;
}
.landingWrap .landRight .roundTube {
  width: 320px;
  position: relative;
  z-index: 2;
  margin: 0 0 0 auto;
}
.landingWrap .landRight .spiral {
  position: absolute;
  top: -9%;
  left: 68%;
  width: 80%;
  height: 189%;
  z-index: 0;
}
.landingWrap h1 {
  font-size: 40px;
}
.landingWrap h3 {
  font-size: 20px;
  position: relative;
}
.landingWrap h3::before {
  content: "";
  position: absolute;
  width: 45%;
  height: 1px;
  background: #b3b3b3;
  bottom: -20px;
  left: 0;
}
.landingWrap p {
  margin-top: 3rem;
}

.companyLogo {
  position: relative;
  display: flex;
  margin-top: 2rem;
}
.companyLogo .CGNameCircle {
  animation: rotate 5s infinite;
  margin: auto;
  width: 9%;
}
.companyLogo .CGLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4%;
}

.ballLightLeft {
  position: relative;
}
.ballLightLeft::after {
  content: "";
  position: absolute;
  left: -30%;
  top: 0%;
  height: 151px;
  width: 151px;
  border-radius: 50%;
  background-image: linear-gradient(#FFE588, #6A8AFF);
  opacity: 1;
  filter: blur(90px);
}

.ballLightRight {
  position: relative;
}
.ballLightRight::before {
  content: "";
  position: absolute;
  right: -22%;
  top: 15%;
  height: 151px;
  width: 151px;
  border-radius: 50%;
  background-image: linear-gradient(#FFE588, #6A8AFF);
  opacity: 1;
  filter: blur(70px);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.glassBg {
  background-color: hsla(0, 0%, 0%, 0.2);
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
}

.servimg {
  height: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.servimg:hover {
  transform: scale(1.1) !important;
}

.homeImage {
  -o-object-fit: cover;
     object-fit: cover;
  width: 500px;
}

.sectionTitle {
  color: #eebf12;
  text-align: center;
  letter-spacing: -0.16px;
  font-family: Mikro, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
}

.swiper-pagination {
  justify-content: flex-end;
}

.hero_7_wrap {
  min-height: 100vh;
  position: relative;
}

.cs-hero.cs-style4,
.cs-hero.cs-style5 {
  align-items: center;
  display: flex;
  height: 100vh;
  padding: 80px 100px;
}

.box-img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
}

.cardWidth {
  width: 45%;
}

.teamPoints {
  width: 65%;
}
.teamPoints p {
  margin-bottom: 1rem;
  text-align: justify;
}

.imageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.imageGrid h5 {
  margin-top: 1rem;
}

.backgroundImageCenter {
  background-position: 78% 100%;
}

.sectionMargin {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.sectionMarginTwo {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.sectionMarginTwo p {
  margin-bottom: 1rem;
}

.sectionPoints li {
  margin-bottom: 1rem;
}

.sectionTwoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.8rem;
}
.sectionTwoGrid ul {
  margin: auto;
}
.sectionTwoGrid ul li {
  margin-bottom: 1rem;
  font-weight: 100;
  font-size: 14px;
}
.sectionTwoGrid img {
  border-radius: 20px;
}

.topTitle h3 {
  font-size: 28px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.portfolioBg {
  background-color: hsla(0, 0%, 94%, 0.05);
  padding: 5rem 0;
}

.portfolioImgBg {
  display: flex;
  justify-content: center;
  position: relative;
}
.portfolioImgBg img {
  width: 40%;
}
.portfolioImgBg .roundBg {
  position: absolute;
  top: -30px;
  display: flex;
  justify-content: center;
  z-index: -1;
}
.portfolioImgBg .roundBg img {
  width: 80%;
  height: 100%;
}

.portfolioLeft a {
  background-color: #000;
  border-radius: 3rem;
  padding: 0.5rem 1rem;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin-top: 2rem;
  font-size: 14px;
}
.portfolioLeft a img {
  width: 8px;
  margin-bottom: 3px;
  margin-left: 0.5rem;
}
.portfolioLeft h3 {
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
}
.portfolioLeft h6 {
  font-size: 20px;
}

.techTitle {
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
}

.techIcon {
  padding: 1rem;
  width: 80%;
  height: 80%;
  margin: auto;
  transition: all 0.3s ease;
  border: 1px solid hsla(0, 0%, 94%, 0.05);
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132), 0 0.3px 0.9px rgba(0, 0, 0, 0.108);
  padding: 20px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 94%, 0.05);
}

.techIconOne {
  width: 80%;
  height: 110%;
  margin: auto;
  transition: all 0.3s ease;
  border: 1px solid hsla(0, 0%, 94%, 0.05);
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132), 0 0.3px 0.9px rgba(0, 0, 0, 0.108);
  padding: 20px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 94%, 0.05);
}

.techtextOne {
  opacity: 1;
}

.techtext {
  opacity: 0;
}

.imgGrid {
  background-color: rgba(240, 240, 240, 0.2117647059);
  border-radius: 1.5rem;
  height: 85px;
  width: 85px;
  display: flex;
  margin-bottom: 2rem;
}
.imgGrid img {
  width: 70%;
  margin: auto;
}

.ballLightVison {
  position: relative;
}
.ballLightVison::after {
  content: "";
  position: absolute;
  left: -14%;
  top: 14%;
  height: 215px;
  width: 216px;
  border-radius: 50%;
  background-image: linear-gradient(#ffe588, #6a8aff);
  opacity: 1;
  filter: blur(110px);
}

.ballLightTeam {
  position: relative;
}
.ballLightTeam::before {
  content: "";
  position: absolute;
  right: -10%;
  top: 100%;
  height: 215px;
  width: 216px;
  border-radius: 50%;
  background-image: linear-gradient(#ffe588, #6a8aff);
  opacity: 1;
  filter: blur(110px);
}

.ballLightTech {
  position: relative;
}
.ballLightTech::before {
  content: "";
  position: absolute;
  left: -29%;
  top: 0%;
  height: 215px;
  width: 216px;
  border-radius: 50%;
  background-image: linear-gradient(#ffe588, #6a8aff);
  opacity: 1;
  filter: blur(110px);
}

.containerBg {
  background-color: #101010;
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
}

.cs-partner_logo_wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0px;
}

.cs-partner_logo {
  padding: 0px !important;
  width: 95px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.logoimg {
  width: 100%;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}

@media screen and (max-width: 575px) {
  .cs-partner_logo_wrap {
    margin: 0 0px !important;
  }
  /* logolist.scss */
  .cs-partner_logo_wrap .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cs-partner_logo_wrap .cs-partner_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .cs-partner_logo_wrap .logoimg {
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
.cs-height_0 {
  height: 0px;
}

.cs-height_5 {
  height: 5px;
}

.cs-height_10 {
  height: 10px;
}

.cs-height_15 {
  height: 15px;
}

.cs-height_20 {
  height: 20px;
}

.cs-height_25 {
  height: 25px;
}

.cs-height_30 {
  height: 30px;
}

.cs-height_35 {
  height: 35px;
}

.cs-height_40 {
  height: 40px;
}

.cs-height_45 {
  height: 45px;
}

.cs-height_50 {
  height: 50px;
}

.cs-height_55 {
  height: 55px;
}

.cs-height_60 {
  height: 60px;
}

.cs-height_65 {
  height: 65px;
}

.cs-height_70 {
  height: 70px;
}

.cs-height_75 {
  height: 75px;
}

.cs-height_85 {
  height: 85px;
}

.cs-height_90 {
  height: 90px;
}

.cs-height_95 {
  height: 95px;
}

.cs-height_100 {
  height: 100px;
}

.cs-height_105 {
  height: 105px;
}

.cs-height_110 {
  height: 110px;
}

.cs-height_115 {
  height: 115px;
}

.cs-height_120 {
  height: 120px;
}

.cs-height_125 {
  height: 125px;
}

.cs-height_130 {
  height: 130px;
}

.cs-height_135 {
  height: 135px;
}

.cs-height_140 {
  height: 140px;
}

.cs-height_145 {
  height: 145px;
}

.cs-height_150 {
  height: 150px;
}

.cs-height_155 {
  height: 155px;
}

.cs-height_160 {
  height: 160px;
}

.cs-height_165 {
  height: 165px;
}

.cs-height_170 {
  height: 170px;
}

.cs-height_175 {
  height: 175px;
}

.cs-height_180 {
  height: 180px;
}

.cs-height_185 {
  height: 185px;
}

.cs-height_190 {
  height: 190px;
}

.cs-height_195 {
  height: 195px;
}

.cs-height_200 {
  height: 200px;
}

.cs-height_205 {
  height: 205px;
}

.cs-height_210 {
  height: 210px;
}

@media screen and (max-width: 991px) {
  .cs-height_lg_0 {
    height: 0px;
  }
  .cs-height_lg_5 {
    height: 5px;
  }
  .cs-height_lg_10 {
    height: 10px;
  }
  .cs-height_lg_15 {
    height: 15px;
  }
  .cs-height_lg_20 {
    height: 20px;
  }
  .cs-height_lg_25 {
    height: 25px;
  }
  .cs-height_lg_30 {
    height: 30px;
  }
  .cs-height_lg_35 {
    height: 35px;
  }
  .cs-height_lg_40 {
    height: 40px;
  }
  .cs-height_lg_45 {
    height: 45px;
  }
  .cs-height_lg_50 {
    height: 50px;
  }
  .cs-height_lg_55 {
    height: 55px;
  }
  .cs-height_lg_60 {
    height: 60px;
  }
  .cs-height_lg_65 {
    height: 65px;
  }
  .cs-height_lg_70 {
    height: 70px;
  }
  .cs-height_lg_75 {
    height: 75px;
  }
  .cs-height_lg_80 {
    height: 80px;
  }
  .cs-height_lg_85 {
    height: 85px;
  }
  .cs-height_lg_90 {
    height: 90px;
  }
  .cs-height_lg_95 {
    height: 95px;
  }
  .cs-height_lg_100 {
    height: 100px;
  }
  .cs-height_lg_105 {
    height: 105px;
  }
  .cs-height_lg_110 {
    height: 110px;
  }
  .cs-height_lg_115 {
    height: 115px;
  }
  .cs-height_lg_120 {
    height: 120px;
  }
  .cs-height_lg_125 {
    height: 125px;
  }
  .cs-height_lg_130 {
    height: 130px;
  }
  .cs-height_lg_135 {
    height: 135px;
  }
  .cs-height_lg_140 {
    height: 140px;
  }
  .cs-height_lg_145 {
    height: 145px;
  }
  .cs-height_lg_150 {
    height: 150px;
  }
  .cs-height_lg_155 {
    height: 155px;
  }
  .cs-height_lg_160 {
    height: 160px;
  }
  .cs-height_lg_165 {
    height: 165px;
  }
  .cs-height_lg_170 {
    height: 170px;
  }
  .cs-height_lg_175 {
    height: 175px;
  }
  .cs-height_lg_180 {
    height: 180px;
  }
  .cs-height_lg_185 {
    height: 185px;
  }
  .cs-height_lg_190 {
    height: 190px;
  }
  .cs-height_lg_195 {
    height: 195px;
  }
  .cs-height_lg_200 {
    height: 200px;
  }
  .cs-height_lg_205 {
    height: 205px;
  }
  .cs-height_lg_210 {
    height: 210px;
  }
}
/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
:root {
  --accent: #be9913;
}

.cs-fooer_main {
  padding: 60px 0 60px;
}

.cs-footer_item {
  margin-bottom: 40px;
  color: #aaa;
}
.cs-footer_item .cs-newsletter.cs-style1 {
  margin-top: 6px;
}

.cs-widget_title {
  font-size: 20px;
  margin-bottom: 20px;
}

.cs-menu_widget.cs-style1 {
  padding: 0;
  margin: 0;
  list-style: none;
}
.cs-menu_widget.cs-style1 li {
  display: flex;
  grid-gap: 1rem;
  align-items: start;
}
.cs-menu_widget.cs-style1 li span {
  font-size: 24px;
  flex: none;
  width: 35px;
}
.cs-menu_widget.cs-style1 li:not(:last-child) {
  margin-bottom: 20px;
}

.cs-menu_widget.cs-style2 {
  display: flex;
  flex-wrap: wrap;
}
.cs-menu_widget.cs-style2 li:not(:last-child)::after {
  content: "|";
  margin: 0 10px;
  position: relative;
  top: -1px;
}

.cs-text_widget p {
  margin: 0;
}
.cs-text_widget img + p {
  margin-top: 30px;
}

.cs-social_btns.cs-style1 {
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
}
.cs-social_btns.cs-style1 a {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 7px;
  color: #fff;
}
.cs-social_btns.cs-style1 a:hover {
  border-color: var(--accent);
  transform: scale(1.08);
}
.cs-social_btns.cs-style1 a:hover svg {
  fill: var(--accent);
}

.cs-bottom_footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid #4d4d4d;
}
.cs-bottom_footer > * {
  padding: 4px 0;
}

.contactIcon img {
  width: 20px;
  height: 20px;
  margin-top: 0.3rem;
}

.ballLightFooter {
  position: relative;
  background-color: #010101;
}
.ballLightFooter::after {
  content: "";
  position: absolute;
  left: 45%;
  bottom: 4%;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-image: linear-gradient(#ffe588, #6a8aff);
  opacity: 1;
  filter: blur(88px);
}

.logoFooterWidth {
  width: 200px;
}

.socialLink {
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: inline-flex;
  grid-gap: 1rem;
  align-items: center;
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  line-height: 1.3;
  margin: 2rem 0;
  width: 160px;
  position: relative;
}
.socialLink::before {
  position: absolute;
  content: "";
  display: block;
  width: 1px;
  height: 65%;
  left: 52px;
  background-color: #fff;
  margin: 0.5rem 0;
}
.socialLink:hover {
  background-color: #0077B5;
  color: #fff;
  border: 1px solid #0077B5;
}

h4 {
  margin-bottom: 5px;
}

p {
  margin-bottom: 0px;
}

.heading {
  border: 1px solid hsla(0, 0%, 94%, 0.05);
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132), 0 0.3px 0.9px rgba(0, 0, 0, 0.108);
  padding: 20px;
  position: relative;
  border-radius: 20px;
  background-color: hsla(0, 0%, 94%, 0.05);
}
.heading p {
  font-size: 12px;
  color: #fff;
  font-style: normal;
  margin-left: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.heading button {
  width: 106px;
  height: 32px;
  margin-left: 0px;
  background: #010100;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #161c2a;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
}
.heading button:hover {
  background-color: #be9913;
  color: white;
}
.heading button img {
  width: 6px;
  margin-left: 0.5rem;
}
.heading h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-left: 0px;
  text-align: left;
  color: #fff;
}

.subContent {
  padding-top: 10px;
  align-items: center;
}
.subContent .subHeading {
  font-size: 12px;
  color: #aaaaaa;
  margin-right: 10px;
  font-style: normal;
  white-space: nowrap;
  padding-top: 3px;
}

.carrierpageh4_fontsize {
  font-size: 32px;
}

.selectedOptions {
  width: 100%;
}

.dflex {
  display: flex;
}

.mg10 {
  margin-left: 9px;
}

.likopen {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #be9913;
  background-color: white;
}

.a,
.likopen:active {
  text-decoration: underline;
}

.selectedOptionsSep {
  background-color: #be9913;
  padding: 2px 5px;
  margin-bottom: 1rem;
  color: black;
}
.selectedOptionsSep button {
  border: none;
  background-color: #be9913;
  color: black;
}

.selectedFlex {
  grid-gap: 1rem;
}

.w-255 {
  width: 435px;
}

.moreCareers {
  height: 234;
  border: 1px solid #ddd;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.careearFlex {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}

.careerGrid {
  padding-top: 0px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-gap: 1.4rem;
  padding-bottom: 2rem;
}

.cardImageFour {
  height: 14px;
  margin-right: 5px;
  float: left;
}

input[type=text],
input[type=email],
input[type=password],
input[type=submit],
textarea {
  background-color: var(--white);
  border-radius: 10px !important;
}

.pageTopPadding {
  padding-top: 90px;
}

.jobInfoWrapper {
  background-color: #907575;
  border: 1px solid #ddd;
  position: relative;
  border-radius: 2px;
  height: auto;
  padding: 110px 24px 24px;
}
.jobInfoWrapper h2 {
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0px;
  color: rgb(0, 0, 0);
}

.flex-column {
  flex-direction: column;
}

.jobKeys {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 10px;
  grid-gap: 1rem;
  border-bottom: 1px solid rgb(237, 235, 233);
  padding-bottom: 1rem;
}

.jobKeys p {
  margin-bottom: 0;
  font-size: 12px;
}

.jobKeys span {
  font-weight: 600;
  font-size: 12px;
  margin-right: 1rem;
}

.jobKeysFlex {
  display: flex;
  margin: 0;
}

.jobKeysFlex .firstdiv {
  display: flex;
  width: 33%;
  font-size: 14px;
}

.jobKeysFlex .seconddiv {
  font-weight: 600;
  font-size: 14px;
}

.overView h3 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
  line-height: 24px;
  color: #171717;
}
.overView p {
  font-size: 14px;
}
.overView li {
  font-size: 14px;
}

.overViewLeft {
  width: 65%;
}

.overViewRight {
  width: 30%;
}

.benefitsSection {
  border: 1px solid rgb(237, 235, 233);
  border-left: none;
  border-right: none;
  padding: 1rem 0;
}

.benefits {
  width: 30%;
}

.benefitsTwo {
  width: 70%;
  font-size: 12px;
}

.jobContents {
  height: auto;
  border: 1px solid #ddd;
  padding: 24px;
}

.joinPoints {
  margin: auto;
}
.joinPoints li {
  margin-bottom: 1rem;
}

.jobContents p {
  font-size: 14px;
  color: #5c727d;
  font-style: normal;
  text-align: left;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jobContents h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: left;
  color: #171717;
}

.activess {
  border-left: 2px solid #be9913;
}

.careearAll {
  height: 70vh;
  overflow-y: scroll;
  display: block;
}

.careearAll button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.btn {
  font-size: 12px;
  font-weight: 100;
  padding: 6px 14px !important;
  text-align: center;
  border-radius: 5px;
}

.theme-btn-1:hover {
  background-color: #bf9a0e;
  border-radius: 0px;
}

.paddingTop {
  padding-top: 110px;
}
.paddingTop h2 {
  font-size: 32px;
}

.contentGrid {
  display: grid;
  grid-template-columns: 30% 70%;
}

.contentGridLeft {
  padding: 24px 16px;
}
.contentGridLeft .contentSection {
  border-bottom: 1px solid #e5e6e8;
  padding: 16px;
}
.contentGridLeft .contentSection .firstdiv {
  font-size: 12px;
  margin-bottom: 8px;
}
.contentGridLeft .contentSection .seconddiv {
  font-size: 16px;
}

.cardFlex {
  grid-gap: 1rem;
}

.contentGridRight {
  padding: 24px 16px;
}
.contentGridRight .contentPoints h3 {
  font-size: 16px;
}
.contentGridRight .contentPoints ul {
  margin-left: 2rem;
}
.contentGridRight .contentPoints li {
  font-size: 14px;
}
.contentGridRight .applyButton {
  background-color: #22273b;
  color: white;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding: 14px !important;
}
.contentGridRight .applyButton:hover {
  background-color: #be9913;
  color: white;
}
.contentGridRight h2 {
  margin-bottom: 3rem;
}

.backButton {
  border: none;
  background-color: transparent;
}

.tabs {
  margin-bottom: 1rem;
  position: relative;
}
.tabs button {
  width: 200px;
  color: white;
  background-color: transparent;
  border: none;
  padding-bottom: 8px;
  text-align: left;
  height: 36px;
}
.tabs button:hover {
  border-bottom: 2px solid #7f7f7f;
}
.tabs .applicationButton {
  margin-left: 16px;
}
.tabs .active {
  color: #be9913;
}
.tabs .tabBorderBottom {
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  background-color: #be9913;
  border-radius: 0.5rem;
  bottom: 0;
  height: 2px;
  left: 0;
  position: absolute;
  transition-property: transform;
  width: 200px;
}
.tabs .tabBorderBottomActive {
  transform: translate(216px);
}

.ballLightCareers {
  position: relative;
}
.ballLightCareers::before {
  content: "";
  position: absolute;
  left: -15%;
  top: 45%;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-image: linear-gradient(#ffe588, #6a8aff);
  opacity: 1;
  filter: blur(90px);
}

.designPosition {
  position: relative;
}
.designPosition .midDesign {
  position: absolute;
  left: 0;
  top: 423px;
  height: 100%;
  width: 100%;
}

.applicationContent {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.applicationContent div {
  margin-bottom: 1.5rem;
}

.applicationContent label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.linkMail {
  margin: 0 0.5rem;
  text-decoration: underline;
}

.applicationContent input[type=text],
.applicationContent input[type=email],
.applicationContent input[type=tel],
.applicationContent input[type=file] {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.portfolioCenter {
  text-align: center;
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
}

.applicationContent input[type=text]:focus,
.applicationContent input[type=email]:focus,
.applicationContent input[type=tel]:focus,
.applicationContent input[type=file]:focus {
  border-color: #be9913;
  outline: none;
  box-shadow: 0 0 5px rgba(190, 153, 19, 0.5);
}

.applicationContent button {
  width: 100%;
  padding: 0.75rem;
  background-color: #be9913;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.applicationContent button:hover {
  background-color: #a57f10;
}

.applicationContent input[type=file] {
  padding: 0.5rem;
}

@media (max-width: 600px) {
  .applicationContent {
    padding: 1.5rem;
  }
  .applicationContent input[type=text],
  .applicationContent input[type=email],
  .applicationContent input[type=tel],
  .applicationContent input[type=file] {
    font-size: 0.9rem;
  }
  .applicationContent button {
    font-size: 0.9rem;
  }
}
.applicationContent .file-upload {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  color: #999;
  font-size: 1rem;
  position: relative;
}

.applicationContent .file-upload:hover {
  border-color: #be9913;
}

.applicationContent .file-upload.dragover {
  border-color: #be9913;
  background-color: #f8f8f8;
}

.applicationContent .file-upload p {
  margin: 0;
  font-size: 1rem;
  color: #666;
}

.applicationContent .file-upload input[type=file] {
  display: none;
}

.applicationContent .file-upload.dragover p {
  color: #333;
}

.applicationContent .file-upload .file-name {
  margin-top: 1rem;
  color: #333;
  font-weight: bold;
  font-size: 1rem;
}

.applicationContent .file-upload .file-icon {
  font-size: 2rem;
  color: #ccc;
  margin-bottom: 0.5rem;
}

.applicationContent .file-upload.dragover .file-icon {
  color: #be9913;
}

.applicationContent button {
  margin-top: 1.5rem;
  width: 100%;
  padding: 0.75rem;
  background-color: #be9913;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.applicationContent button:hover {
  background-color: #a57f10;
}

@media (max-width: 400px) {
  .jobKeys {
    grid-template-columns: 1fr;
  }
  .carrierpageh4_fontsize {
    font-size: 22px;
  }
  .jobKeysFlex .seconddiv {
    font-weight: 600;
    font-size: 12px;
  }
  .jobKeysFlex .firstdiv {
    display: flex;
    width: 50%;
    font-size: 12px;
  }
  .careearAll {
    height: 33vh;
    overflow-y: scroll;
    display: block;
  }
  .jobKeysFlex {
    display: flex;
    margin: 20px 0;
  }
  .jobInfoWrapper {
    padding-inline: 1rem;
  }
  .jobContents {
    /* border: 1px solid #ddd; */
    height: auto;
    padding: 15px 15px;
  }
  .jobKeys {
    grid-gap: 6px;
    border-bottom: 1px solid #edebe9;
    display: contents;
  }
  .careearAll {
    height: 33vh;
    overflow-y: scroll;
    display: block;
  }
  .jobContents .flex-column {
    flex-direction: column !important;
  }
  .subContent {
    padding-top: 0px;
    margin-top: 12px;
  }
  .flex-column .subContent {
    padding-top: 0px;
    margin-top: 0px !important;
  }
}
@media (max-width: 750px) {
  .jobKeysFlex {
    display: flex;
    margin: 20px 0;
  }
  .jobContents .flex-column {
    flex-direction: column !important;
  }
  .careearAll {
    height: 44vh;
    overflow-y: scroll;
    display: block;
  }
  .jobKeys {
    grid-gap: 6px;
    border-bottom: 1px solid #edebe9;
    display: contents;
  }
}
@media (max-width: 800px) {
  .searchFilter {
    padding: 2rem 1.5rem;
    grid-gap: 1rem;
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .jobKeysFlex {
    display: flex;
    margin: 20px 0;
  }
  .jobInfoWrapper {
    margin-top: 20px;
  }
}
@media (max-width: 1320px) {
  .flex-column {
    flex-direction: column !important;
  }
}
@media (max-width: 480px) {
  .careerGrid {
    grid-template-columns: 1fr;
  }
  .moreCareers {
    width: 100%;
    padding: 20px;
    margin-bottom: 30px;
  }
  .w-255 {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .careerGrid {
    grid-template-columns: 1fr;
  }
  .careearFlex {
    margin-left: 0px;
  }
  .searchFilter {
    padding: 2rem 2rem;
    grid-gap: 1rem;
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .ltn__banner-info h5 {
    margin-bottom: 30px;
  }
  .footer-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .jobContents .flex-column {
    flex-direction: column !important;
  }
  .jobKeysFlex {
    display: flex;
    margin: 10px 0;
  }
  .careearAll {
    height: 33vh;
  }
  p {
    margin-bottom: 0px;
  }
}
@media (max-width: 780px) {
  .careerGrid {
    grid-template-columns: 1fr;
  }
  h4 {
    margin-bottom: 10px;
  }
  .padd34 {
    padding-top: 0px;
  }
  .careearAll {
    display: block;
    height: 16vh;
    overflow-y: scroll;
  }
  .dropdown-list {
    margin-top: 16px;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .paddingTop h2 {
    font-size: 24px;
  }
  .contentGrid {
    grid-template-columns: 1fr;
  }
}
.searchFilter {
  padding: 2rem 3.2rem 0px;
  grid-gap: 1rem;
  display: flex;
  width: 50%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the element horizontally */
  text-align: center; /* Optional: Center text inside the block */
}

@media (max-width: 767px) {
  .dropdown-header {
    margin-top: 10px;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    width: 215px;
  }
  input[type=text],
  input[type=email],
  input[type=password],
  input[type=submit],
  textarea {
    background-color: var(--white);
    border: 1px solid;
    border-color: #aaaaaa;
    height: 38px;
    box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 1px;
    border-radius: 10px;
    padding-right: 38px;
  }
  .dropdown {
    position: relative;
    /* display: none !important; */
    width: 205px;
  }
  .dropdown {
    display: contents;
  }
  .searchFilter {
    padding: 1rem 3.2rem 0px;
    grid-gap: 1rem;
    display: grid !important;
    width: 100%;
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 770px) {
  .allJobs {
    display: none;
  }
  .careerGrid {
    grid-template-columns: 1fr;
  }
  .w-255 {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .cs-left_space_30 {
    padding-left: 0px;
  }
  .cs-font_14_sm {
    font-size: 14px;
  }
  .cs-font_16_sm {
    font-size: 16px;
  }
  .cs-font_18_sm {
    font-size: 18px;
  }
  .cs-font_20_sm {
    font-size: 20px;
  }
  .cs-font_22_sm {
    font-size: 22px;
  }
  .cs-font_24_sm {
    font-size: 24px;
  }
  .cs-font_26_sm {
    font-size: 26px;
  }
  .cs-font_28_sm {
    font-size: 28px;
  }
  .cs-font_42_sm {
    font-size: 42px;
  }
  .cs-font_36_sm {
    font-size: 36px;
  }
  .cs-btn_group > *:not(:last-child) {
    margin-right: 10px;
  }
  .flex-column-reverse-lg {
    flex-direction: column-reverse;
  }
  .cs-seciton_heading.cs-style1 .cs-section_title::before {
    top: 1px;
  }
  .landingWrap .landRight .spiral {
    left: 60%;
    height: 155%;
  }
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
  .cs-right_space_40 {
    padding-right: 40px;
  }
  .heading {
    width: 455px;
  }
  .careearFlex {
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }
  .landingWrap .landRight .spiral {
    left: 60%;
    height: 180%;
  }
}
@media screen and (max-width: 1398px) and (min-width: 1201px) {
  .landingWrap .landRight .spiral {
    left: 60%;
    height: 186%;
  }
}
@media screen and (max-width: 991px) {
  .landingWrap .landRight .roundTube {
    height: 100%;
  }
  .cs-bottom_footer {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .landingWrap h1 {
    font-size: 30px;
  }
  .sectionTwoGrid {
    grid-template-columns: 1fr;
  }
  .imageGrid {
    grid-template-columns: 1fr 1fr;
  }
  .joinPoints {
    margin-top: 1rem;
  }
  .companyLogo .CGLogo {
    width: 10%;
  }
  .companyLogo .CGNameCircle {
    width: 20%;
  }
  .careearFlex {
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 540px) and (min-width: 250px) {
  h2 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .mb-sm-4 {
    margin-bottom: 20px;
  }
  .pt4-sm-4 {
    padding-top: 20px;
  }
  .mhide {
    display: none;
  }
  .center-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center-image img {
    -o-object-fit: contain;
       object-fit: contain;
  }
  .cardWidth {
    width: 100%;
  }
  .teamPoints {
    width: 100%;
  }
  .teamPoints p {
    text-align: center;
  }
  .heading {
    width: 100%;
  }
  .careearFlex {
    margin: auto;
    grid-template-columns: 1fr;
  }
  .mobileSection h3 {
    margin-top: 1.5rem;
    text-align: center;
  }
  .joinPoints {
    margin-top: 2rem;
  }
  .sectionPoints {
    padding: 0;
  }
  .imageGrid {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
  }
  .whatDoCenter {
    text-align: center;
  }
  .textservice-center {
    text-align: center;
  }
  .logowidth {
    width: 165px;
  }
  .servimg {
    width: 392px;
    height: auto !important;
  }
  h4 {
    font-size: 16px;
  }
  .boredr img {
    height: auto;
  }
  .margin-left-serviceimage {
    margin-top: 20px;
  }
  .landingWrap h1 {
    font-size: 30px;
  }
  .sectionTwoGrid {
    grid-template-columns: 1fr;
  }
  .imageGrid {
    grid-template-columns: 1fr 1fr;
  }
  .joinPoints {
    margin-top: 1rem;
  }
  .landingWrap {
    grid-template-columns: 1fr;
  }
  .teamimg {
    width: 550px;
  }
  .aboutimg {
    width: 33%;
  }
  .custom-margin-left {
    margin-left: 20px;
    margin-right: 20px;
  }
  .companyLogo .CGLogo {
    width: 20%;
  }
  .companyLogo .CGNameCircle {
    width: 40%;
  }
  .landingWrap .landRight .roundTube {
    display: none;
  }
  .landingWrap .landRight .spiral {
    position: absolute;
    top: -17%;
    left: 15%;
    width: 100%;
    height: 162%;
    z-index: 0;
  }
  .portfolioImgBg .roundBg img {
    width: 68%;
  }
}
.serviceimg {
  width: 389px;
  height: 242px;
}

.textunderline {
  text-decoration: underline;
}

.sticky-scroll-reveal {
  position: sticky;
  top: 0;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.9s ease-out, transform 0.9s ease-out;
}

.sticky-scroll-reveal.revealed {
  opacity: 1;
  transform: translateY(0);
}

.scroll-beam {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.scroll-beam .box-img {
  transition: transform 0.3s ease-in-out;
}

/* Optional: Style for scrollbar (for webkit browsers) */
.scroll-beam::-webkit-scrollbar {
  height: 8px;
}/*# sourceMappingURL=index.css.map */