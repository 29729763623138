.cs-light {
  font-weight: 300;
}

.cs-normal {
  font-weight: 400;
}

.cs-medium {
  font-weight: 500;
}

.cs-semi_bold {
  font-weight: 600;
}

.cs-bold {
  font-weight: 700;
}

.cs-extra_bold {
  font-weight: 800;
}

.cs-black {
  font-weight: 900;
}

.cs-radius_3 {
  border-radius: 3px;
}

.cs-radius_5 {
  border-radius: 5px;
}

.cs-radius_7 {
  border-radius: 7px;
}

.cs-radius_10 {
  border-radius: 10px;
}

.cs-radius_15 {
  border-radius: 15px;
}

.cs-line_height_1 {
  line-height: 1.2em;
}
.cs-line_height_2 {
  line-height: 1.25em;
}
.cs-line_height_3 {
  line-height: 1.3em;
}
.cs-line_height_4 {
  line-height: 1.4em;
}
.cs-line_height_5 {
  line-height: 1.5em;
}
.cs-line_height_6 {
  line-height: 1.6em;
}
.cs-line_height_7 {
  line-height: 1.7em;
}

.cs-font_14 {
  font-size: 14px;
}

.cs-font_16 {
  font-size: 16px;
}

.cs-font_18 {
  font-size: 18px;
}

.cs-font_20 {
  font-size: 20px;
}

.cs-font_22 {
  font-size: 22px;
}

.cs-font_28 {
  font-size: 28px;
}

.cs-font_30 {
  font-size: 28px;
}

.cs-font_38 {
  font-size: 38px;
}

.cs-font_42 {
  font-size: 42px;
}

.cs-font_50 {
  font-size: 40px;
}

.cs-mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cs-m0 {
  margin: 0;
}

hr {
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid #f2f1ff;
}

.cs-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.cs-vertical_middle {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.cs-vertical_middle_in {
  flex: none;
  width: 100%;
}

.cs-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs-white_color,
.cs-white_color_hover:hover {
  color: $white;
}

.cs-primary_color {
  color: $primary;
}

.cs-ternary_color {
  color: $ternary;
}

.cs-accent_color,
.cs-accent_color_hover:hover {
  color: $accent;
}

.cs-white_bg {
  background-color: #fff;
}

.cs-gray_bg {
  background-color: $gray;
}

.cs-accent_bg,
.cs-accent_bg_hover:hover {
  background-color: $accent;
}

.cs-primary_bg,
.cs-primary_bg_hover:hover {
  background-color: $primary;
}

.cs-gradient_bg_1 {
  background: linear-gradient(267.18deg, #161616 0%, #080808 100%);
}

.cs-text_btn {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: $white;
  cursor: pointer;
  svg {
    margin-left: 15px;
    font-size: 24px;
  }
  span {
    display: inline-block;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-duration: 0.6s;
      transition-timing-function: ease-out;
    }
  }
  &:hover {
    span {
      &::before {
        transform: scaleX(1);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      }
    }
  }
  &.cs-type1 {
    flex-direction: row-reverse;
    svg {
      margin-left: 0px;
      margin-right: 15px;
      transform: rotate(180deg);
    }
    span {
      &::before {
        left: initial;
        right: -100%;
        transform: scaleX(0) rotateY(180deg);
      }
    }
    &:hover {
      span {
        &::before {
          transform: scaleX(1) rotateY(180deg);
        }
      }
    }
  }
  &.cs-type2 {
    flex-direction: row-reverse;
    svg {
      margin-left: 0;
      margin-right: 15px;
      color: $accent;
    }
  }
}

label {
  margin-bottom: 12px;
}

/* Start Accordion */
.cs-accordian_title {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 100;
  transition: all 0.3s ease;
  @media (max-width: 991px) {
    font-size: 18px;
  }
}
.cs-accordian_body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  p {
    margin: 0;
  }
}
.cs-accordian_body_in {
  line-height: 1.8em;
  padding-top: 15px;
}
.cs-accordian_head {
  padding: 2px 20px 2px 0;
  position: relative;
  cursor: pointer;
  &:hover {
    .cs-accordian_title {
      color: $accent;
    }
  }
}
.cs-accordian {
  border-bottom: 2px solid $accent;
  padding-bottom: 25px;
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  @media (max-width: 991px) {
    padding-bottom: 15px;
    border-width: 1px;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
.cs-accordian_toggle {
  display: flex;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 0;
  transition: all 0.3s ease;
}
.cs-accordian.active {
  .cs-accordian_toggle {
    transform: rotate(180deg);
  }
  .cs-accordian_head {
    pointer-events: none;
  }
  .cs-accordian_body {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  }
}
/* End Accordion */

.cs-partner_logo_wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
}
.cs-partner_logo {
  padding: 20px;
}

.cs-page_heading.cs-style1 {
  height: 200px;
  padding: 101px 0 0px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: -30px;
    top: -30px;
    height: 151px;
    width: 151px;
    background: $accent;
    opacity: 0.8;
    filter: blur(125px);
  }
  > * {
    position: relative;
    z-index: 2;
  }
  .cs-page_heading_in {
    display: inline-block;
  }
  .breadcrumb {
    justify-content: flex-end;
    margin-bottom: 0;
    color: $ternary;
  }
  .breadcrumb-item.active {
    color: $white;
  }
  .cs-page_title {
    margin-bottom: 18px;
  }
}

.cs-google_map {
  height: 600px;
  iframe {
    height: 100%;
    display: block;
    border: none;
    width: 100%;
    filter: grayscale(100%) invert(90%) contrast(120%);
  }
  &.cs-type1 {
    height: 100%;
    min-height: 300px;
    width: 50vw;
  }
}

.cs-shine_hover_1 {
  position: relative;
  overflow: hidden;
  width: 240px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -80%;
    z-index: 2;
    width: 50%;
    height: 100%;
    // background: linear-gradient(
    //   to right,
    //   transparent 0%,
    //   rgba(255, 255, 255, 0.15) 100%
    // );
    transform: skewX(-25deg);
    z-index: 1;
  }
  &:hover {
    &::before {
      animation: shine 1.3s;
    }
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.cs-error_page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1380px) {
  .cs-section_heading.cs-style1 .cs-section_title {
    font-size: 42px;
  }
  .cs-font_50 {
    font-size: 42px;
  }
  .cs-shape_wrap_4 .cs-shape_4:nth-child(2) {
    height: 200px;
    width: 200px;
  }
  .cs-page_heading.cs-style1 {
    height: 200px;
  }
}

@media screen and (max-width: 1199px) {
  .cs-page_heading.cs-style1 {
    height: 200px;
  }
  .cs-half_screen {
    width: 100%;
  }
  .cs-video_block.cs-style1.cs-size1 {
    height: 450px;
  }
  .cs-case_study .cs-case_study_title {
    font-size: 60px;
  }
  .cs-masonry_4_col {
    column-count: 2;
  }
  .cs-half_of_full_width {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .cs-section_heading.cs-style1 {
    .cs-section_title {
      font-size: 30px;
    }
    .cs-section_subtitle {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .cs-google_map {
    height: 400px;
  }

  .cs-column_reverse_lg {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 767px) {
  .cs-tabs.cs-style1 {
    .cs-tabs_heading {
      display: block;
    }
    .cs-section_heading + .cs-tab_links {
      margin-top: 50px;
    }
  }
  .cs-case_study {
    padding: 80px 12px;
    .cs-case_study_title {
      font-size: 40px;
      padding: 25px 25px;
    }
  }
}
@media screen and (max-width: 575px) {
  .cs-partner_logo {
    padding: 5px;
  }

  .cs-partner_logo_wrap {
    margin: 0 -10px;
  }

  .cs-page_heading.cs-style1 {
    .breadcrumb {
      justify-content: center;
    }
  }
}

.mb-4 {
  margin-bottom: 1.5rem !important;
  padding: 0px !important;
}
