.glassBg {
  background-color: hsl(0deg 0% 0% / 20%);
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
}
.servimg {
  height: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1) !important;
  }
}
.homeImage {
  object-fit: cover;
  width: 500px;
}

.sectionTitle {
  color: #eebf12;
  text-align: center;
  letter-spacing: -0.16px;
  font-family: Mikro, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
}

.swiper-pagination {
  justify-content: flex-end;
}

.hero_7_wrap {
  min-height: 100vh;
  position: relative;
}

.cs-hero.cs-style4,
.cs-hero.cs-style5 {
  align-items: center;
  display: flex;
  height: 100vh;
  padding: 80px 100px;
}

.box-img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
}
.cardWidth {
  width: 45%;
}
.teamPoints {
  width: 65%;
  p {
    margin-bottom: 1rem;
    text-align: justify;
  }
}
.imageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  h5 {
    margin-top: 1rem;
  }
}

.backgroundImageCenter {
  background-position: 78% 100%;
}
.sectionMargin {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.sectionMarginTwo {
  margin-top: 4rem;
  margin-bottom: 4rem;
  p {
    margin-bottom: 1rem;
  }
}
.sectionPoints {
  li {
    margin-bottom: 1rem;
  }
}

.sectionTwoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.8rem;
  ul {
    margin: auto;
    li {
      margin-bottom: 1rem;
      font-weight: 100;
      font-size: 14px;
    }
  }
  img {
    border-radius: 20px;
  }
}

.topTitle {
  h3 {
    font-size: 28px;
    margin-bottom: 1.5rem;
    text-align: center;
  }
}

.portfolioBg {
  background-color: hsl(0deg 0% 94% / 5%);
  padding: 5rem 0;
}

.portfolioImgBg {
  display: flex;
  justify-content: center;
  position: relative;
  img {
    width: 40%;
  }
  .roundBg {
    position: absolute;
    top: -30px;
    display: flex;
    justify-content: center;
    z-index: -1;
    img {
      width: 80%;
      height: 100%;
    }
  }
}

.portfolioLeft {
  a {
    background-color: #000;
    border-radius: 3rem;
    padding: 0.5rem 1rem;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin-top: 2rem;
    font-size: 14px;
    img {
      width: 8px;
      margin-bottom: 3px;
      margin-left: 0.5rem;
    }
  }
  h3{
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
  }
  h6{
    font-size: 20px;
  }
}

.techTitle{
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
}

.techIcon {
  padding: 1rem;
  width: 80%;
  height: 80%;
  margin: auto;
  transition: all 0.3s ease;
  border: 1px solid hsl(0deg 0% 94% / 5%);
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px rgba(0, 0, 0, 0.108);
  padding: 20px;
  border-radius: 20px;
  background-color: hsl(0deg 0% 94% / 5%);
}

.techIconOne {
  width: 80%;
  height: 110%;
  margin: auto;
  transition: all 0.3s ease;
  border: 1px solid hsl(0deg 0% 94% / 5%);
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px rgba(0, 0, 0, 0.108);
  padding: 20px;
  border-radius: 20px;
  background-color: hsl(0deg 0% 94% / 5%);
}

.techtextOne {
  opacity: 1;
}

.techtext {
  opacity: 0;
}

.imgGrid {
  background-color: #f0f0f036;
  border-radius: 1.5rem;
  height: 85px;
  width: 85px;
  display: flex;
  margin-bottom: 2rem;
  img {
    width: 70%;
    margin: auto;
  }
}

.ballLightVison {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: -14%;
    top: 14%;
    height: 215px;
    width: 216px;
    border-radius: 50%;
    background-image: linear-gradient(#ffe588, #6a8aff);
    opacity: 1;
    filter: blur(110px);
  }
}

.ballLightTeam {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    right: -10%;
    top: 100%;
    height: 215px;
    width: 216px;
    border-radius: 50%;
    background-image: linear-gradient(#ffe588, #6a8aff);
    opacity: 1;
    filter: blur(110px);
  }
}

.ballLightTech {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: -29%;
    top: 0%;
    height: 215px;
    width: 216px;
    border-radius: 50%;
    background-image: linear-gradient(#ffe588, #6a8aff);
    opacity: 1;
    filter: blur(110px);
  }
}

.containerBg {
  background-color: #101010;
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
}
