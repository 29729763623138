.cs-partner_logo_wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0px;
}
.cs-partner_logo {
  padding: 0px !important;
  width: 95px;
  display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}
.logoimg {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
@media screen and (max-width: 575px) {
  .cs-partner_logo_wrap {
    margin: 0 0px !important;
  }
  /* logolist.scss */

.cs-partner_logo_wrap {
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cs-partner_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .logoimg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

}
// .cs-partner_logo_wrap {
//   margin: 0 -30px !important;
// }