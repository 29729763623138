h4 {
  margin-bottom: 5px;
}
p {
  margin-bottom: 0px;
}

.heading {
  border: 1px solid hsl(0deg 0% 94% / 5%);
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px rgba(0, 0, 0, 0.108);
  padding: 20px;
  position: relative;
  border-radius: 20px;
  background-color: hsl(0deg 0% 94% / 5%);
  & p {
    font-size: 12px;
    color: #fff;
    font-style: normal;
    margin-left: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & button {
    width: 106px;
    height: 32px;
    margin-left: 0px;
    background: #010100;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #161c2a;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    &:hover {
      background-color: #be9913;
      color: white;
    }
    img {
      width: 6px;
      margin-left: 0.5rem;
    }
  }
  & h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-left: 0px;
    text-align: left;
    color: #fff;
  }
}

.subContent {
  padding-top: 10px;
  align-items: center;
  & {
    .subHeading {
      font-size: 12px;
      color: #aaaaaa;
      margin-right: 10px;
      font-style: normal;
      white-space: nowrap;
      padding-top: 3px;
    }
  }
}
.carrierpageh4_fontsize {
  font-size: 32px;
}

.selectedOptions {
  width: 100%;
}

.dflex {
  display: flex;
}
.mg10 {
  margin-left: 9px;
}

.likopen {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #be9913;
  background-color: white;
}
.a,
.likopen:active {
  text-decoration: underline;
}

.selectedOptionsSep {
  background-color: #be9913;
  padding: 2px 5px;
  margin-bottom: 1rem;
  color: black;

  & button {
    border: none;
    background-color: #be9913;
    color: black;
  }
}

.selectedFlex {
  grid-gap: 1rem;
}
.w-255 {
  width: 435px;
}
.moreCareers {
  height: 234;

  border: 1px solid #ddd;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  position: relative;
  width: 100%;
}
.careearFlex {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.careerGrid {
  padding-top: 0px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-gap: 1.4rem;
  padding-bottom: 2rem;
}
.cardImageFour {
  height: 14px;
  margin-right: 5px;
  float: left;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
textarea {
  background-color: var(--white);
  border-radius: 10px !important;
}

.pageTopPadding {
  padding-top: 90px;
}

.jobInfoWrapper {
  background-color: #907575;
  border: 1px solid #ddd;
  position: relative;
  border-radius: 2px;
  height: auto;
  padding: 110px 24px 24px;
  & h2 {
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 0px;
    color: rgb(0, 0, 0);
  }
}
.flex-column {
  flex-direction: column;
}

.jobKeys {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 10px;
  grid-gap: 1rem;
  border-bottom: 1px solid rgb(237, 235, 233);
  padding-bottom: 1rem;
}
.jobKeys p {
  margin-bottom: 0;
  font-size: 12px;
}
.jobKeys span {
  font-weight: 600;
  font-size: 12px;
  margin-right: 1rem;
}
.jobKeysFlex {
  display: flex;
  margin: 0;
}
.jobKeysFlex .firstdiv {
  display: flex;
  width: 33%;
  font-size: 14px;
}
.jobKeysFlex .seconddiv {
  font-weight: 600;
  font-size: 14px;
}

.overView {
  & h3 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 24px;
    color: #171717;
  }
  & p {
    font-size: 14px;
  }
  & li {
    font-size: 14px;
  }
}

.overViewLeft {
  width: 65%;
}
.overViewRight {
  width: 30%;
}
.benefitsSection {
  border: 1px solid rgb(237, 235, 233);
  border-left: none;
  border-right: none;
  padding: 1rem 0;
}
.benefits {
  width: 30%;
}
.benefitsTwo {
  width: 70%;
  font-size: 12px;
}
.jobContents {
  height: auto;
  border: 1px solid #ddd;
  padding: 24px;
}
.joinPoints {
 margin: auto;
  li {
    margin-bottom: 1rem;
  }
}

.jobContents p {
  font-size: 14px;
  color: #5c727d;
  font-style: normal;
  text-align: left;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jobContents h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: left;
  color: #171717;
}
.activess {
  border-left: 2px solid #be9913;
}
.careearAll {
  height: 70vh;
  overflow-y: scroll;
  display: block;
}
.careearAll button {
  border: none;
  background-color: transparent;
  padding: 0;
}
.btn {
  font-size: 12px;
  font-weight: 100;
  padding: 6px 14px !important;
  text-align: center;
  border-radius: 5px;
}
.theme-btn-1:hover {
  background-color: #bf9a0e;
  border-radius: 0px;
}
.paddingTop {
  padding-top: 110px;
  & h2 {
    font-size: 32px;
  }
}

.contentGrid {
  display: grid;
  grid-template-columns: 30% 70%;
}
.contentGridLeft {
  padding: 24px 16px;
  & .contentSection {
    border-bottom: 1px solid #e5e6e8;
    padding: 16px;
    & .firstdiv {
      font-size: 12px;
      margin-bottom: 8px;
    }
    & .seconddiv {
      font-size: 16px;
    }
  }
}

.cardFlex{
  grid-gap: 1rem;
}


.contentGridRight {
  padding: 24px 16px;
  & .contentPoints {
    h3 {
      font-size: 16px;
    }
    & ul {
      margin-left: 2rem;
    }
    & li {
      font-size: 14px;
    }
  }
  & .applyButton {
    background-color: #22273b;
    color: white;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 14px !important;
    &:hover {
      background-color: #be9913;
      color: white;
    }
  }
  h2 {
    margin-bottom: 3rem;
  }
}

.backButton {
  border: none;
  background-color: transparent;
}

.tabs {
  margin-bottom: 1rem;
  position: relative;
  & button {
    width: 200px;
    color: white;
    background-color: transparent;
    border: none;
    padding-bottom: 8px;
    text-align: left;
    height: 36px;

    &:hover {
      border-bottom: 2px solid #7f7f7f;
    }
  }
  .applicationButton {
    margin-left: 16px;
  }
  & .active {
    color: #be9913;
  }
  .tabBorderBottom {
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
    background-color: #be9913;
    border-radius: 0.5rem;
    bottom: 0;
    height: 2px;
    left: 0;
    position: absolute;
    transition-property: transform;
    width: 200px;
  }
  .tabBorderBottomActive {
    transform: translate(calc(200px + 16px));
  }
}

.ballLightCareers {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: -15%;
    top: 45%;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-image: linear-gradient(#ffe588, #6a8aff);
    opacity: 1;
    filter: blur(90px);
  }
}
.designPosition {
  position: relative;
  .midDesign {
    position: absolute;
    left: 0;
    top: 423px;
    height: 100%;
    width: 100%;
  }
}

.applicationContent {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.applicationContent div {
  margin-bottom: 1.5rem;
}

.applicationContent label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.linkMail {
  margin: 0 0.5rem;
  text-decoration: underline;
}
.applicationContent input[type="text"],
.applicationContent input[type="email"],
.applicationContent input[type="tel"],
.applicationContent input[type="file"] {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.portfolioCenter {
  text-align: center;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
}

.applicationContent input[type="text"]:focus,
.applicationContent input[type="email"]:focus,
.applicationContent input[type="tel"]:focus,
.applicationContent input[type="file"]:focus {
  border-color: #be9913;
  outline: none;
  box-shadow: 0 0 5px rgba(190, 153, 19, 0.5);
}

.applicationContent button {
  width: 100%;
  padding: 0.75rem;
  background-color: #be9913;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.applicationContent button:hover {
  background-color: #a57f10;
}

.applicationContent input[type="file"] {
  padding: 0.5rem;
}

@media (max-width: 600px) {
  .applicationContent {
    padding: 1.5rem;
  }

  .applicationContent input[type="text"],
  .applicationContent input[type="email"],
  .applicationContent input[type="tel"],
  .applicationContent input[type="file"] {
    font-size: 0.9rem;
  }

  .applicationContent button {
    font-size: 0.9rem;
  }
}

.applicationContent .file-upload {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  color: #999;
  font-size: 1rem;
  position: relative;
}

.applicationContent .file-upload:hover {
  border-color: #be9913;
}

.applicationContent .file-upload.dragover {
  border-color: #be9913;
  background-color: #f8f8f8;
}

.applicationContent .file-upload p {
  margin: 0;
  font-size: 1rem;
  color: #666;
}

.applicationContent .file-upload input[type="file"] {
  display: none;
}

.applicationContent .file-upload.dragover p {
  color: #333;
}

.applicationContent .file-upload .file-name {
  margin-top: 1rem;
  color: #333;
  font-weight: bold;
  font-size: 1rem;
}

.applicationContent .file-upload .file-icon {
  font-size: 2rem;
  color: #ccc;
  margin-bottom: 0.5rem;
}

.applicationContent .file-upload.dragover .file-icon {
  color: #be9913;
}

.applicationContent button {
  margin-top: 1.5rem;
  width: 100%;
  padding: 0.75rem;
  background-color: #be9913;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.applicationContent button:hover {
  background-color: #a57f10;
}

@media (max-width: 400px) {
  .jobKeys {
    grid-template-columns: 1fr;
  }
  .carrierpageh4_fontsize {
    font-size: 22px;
  }
  .jobKeysFlex .seconddiv {
    font-weight: 600;
    font-size: 12px;
  }
  .jobKeysFlex .firstdiv {
    display: flex;
    width: 50%;
    font-size: 12px;
  }
  .careearAll {
    height: 33vh;
    overflow-y: scroll;
    display: block;
  }
  .jobKeysFlex {
    display: flex;
    margin: 20px 0;
  }
  .jobInfoWrapper {
    padding-inline: 1rem;
  }
  .jobContents {
    /* border: 1px solid #ddd; */

    height: auto;
    padding: 15px 15px;
  }
  .jobKeys {
    grid-gap: 6px;
    border-bottom: 1px solid #edebe9;
    display: contents;
  }
  .careearAll {
    height: 33vh;
    overflow-y: scroll;
    display: block;
  }
  .jobContents .flex-column {
    flex-direction: column !important;
  }

  .subContent {
    padding-top: 0px;
    margin-top: 12px;
  }
  .flex-column .subContent {
    padding-top: 0px;
    margin-top: 0px !important;
  }
}

@media (max-width: 750px) {
  .jobKeysFlex {
    display: flex;
    margin: 20px 0;
  }
  .jobContents .flex-column {
    flex-direction: column !important;
  }
  .careearAll {
    height: 44vh;
    overflow-y: scroll;
    display: block;
  }
  .jobKeys {
    grid-gap: 6px;
    border-bottom: 1px solid #edebe9;
    display: contents;
  }
}
@media (max-width: 800px) {
  .searchFilter {
    padding: 2rem 1.5rem;
    grid-gap: 1rem;
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .jobKeysFlex {
    display: flex;
    margin: 20px 0;
  }
  .jobInfoWrapper {
    margin-top: 20px;
  }
}

@media (max-width: 1320px) {
  .flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 480px) {
  .careerGrid {
    grid-template-columns: 1fr;
  }

  .moreCareers {
    width: 100%;
    padding: 20px;
    margin-bottom: 30px;
  }
  .w-255 {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .careerGrid {
    grid-template-columns: 1fr;
  }
  .careearFlex {
    margin-left: 0px;
  }
  .searchFilter {
    padding: 2rem 2rem;
    grid-gap: 1rem;
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .ltn__banner-info h5 {
    margin-bottom: 30px;
  }
  .footer-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .jobContents .flex-column {
    flex-direction: column !important;
  }

  .jobKeysFlex {
    display: flex;
    margin: 10px 0;
  }
  .careearAll {
    height: 33vh;
  }
  p {
    margin-bottom: 0px;
  }
}

@media (max-width: 780px) {
  .careerGrid {
    grid-template-columns: 1fr;
  }

  h4 {
    margin-bottom: 10px;
  }
  .padd34 {
    padding-top: 0px;
  }

  .careearAll {
    display: block;
    height: 16vh;
    overflow-y: scroll;
  }
  .dropdown-list {
    margin-top: 16px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .paddingTop {
    & h2 {
      font-size: 24px;
    }
  }
  .contentGrid {
    grid-template-columns: 1fr;
  }
}

.searchFilter {
  padding: 2rem 3.2rem 0px;
  grid-gap: 1rem;
  display: flex;
  width: 50%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the element horizontally */
  text-align: center; /* Optional: Center text inside the block */
}
@media (max-width: 767px) {
  .dropdown-header {
    margin-top: 10px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    width: 215px;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="submit"],
  textarea {
    background-color: var(--white);
    border: 1px solid;
    border-color: #aaaaaa;
    height: 38px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    color: var(--ltn__paragraph-color);
    width: 100%;
    margin-bottom: 1px;
    border-radius: 10px;
    padding-right: 38px;
  }
  .dropdown {
    position: relative;
    /* display: none !important; */
    width: 205px;
  }
  .dropdown {
    display: contents;
  }
  .searchFilter {
    padding: 1rem 3.2rem 0px;
    grid-gap: 1rem;
    display: grid !important;
    width: 100%;
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 770px) {
  .allJobs {
    display: none;
  }
  .careerGrid {
    grid-template-columns: 1fr;
  }
  .w-255 {
    width: 100% !important;
  }
}
