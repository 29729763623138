.landingWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 15rem 0 0;
  position: relative;
  .landRight {
    display: flex;
    z-index: 1;
    .roundTube {
      width: 320px;
      position: relative;
      z-index: 2;
      margin: 0 0 0 auto;
    }
    .spiral {
      position: absolute;
      top: -9%;
      left: 68%;
      width: 80%;
      height: 189%;
      z-index: 0;
    }
  }
  h1 {
    font-size: 40px;
  }
  h3 {
    font-size: 20px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 45%;
      height: 1px;
      background: #b3b3b3;
      bottom: -20px;
      left: 0;
    }
  }
  p {
    margin-top: 3rem;
  }
}

.companyLogo {
  position: relative;
  display: flex;
  margin-top: 2rem;
  .CGNameCircle {
    animation: rotate 5s infinite;
    margin: auto;
    width: 9%;
  }
  .CGLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4%;
  }
}

.ballLightLeft{
    position: relative;
    &::after{
        content: "";
        position: absolute;
        left: -30%;
        top: 0%;
        height: 151px;
        width: 151px;
        border-radius: 50%;
        background-image: linear-gradient(#FFE588, #6A8AFF);
        opacity: 1;
        filter: blur(90px);
    }
}

.ballLightRight{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        right: -22%;
        top: 15%;
        height: 151px;
        width: 151px;
        border-radius: 50%;
        background-image: linear-gradient(#FFE588, #6A8AFF);
        opacity: 1;
        filter: blur(70px);
    }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
