@media screen and (max-width: 991px) {
  .cs-left_space_30 {
    padding-left: 0px;
  }
  .cs-font_14_sm {
    font-size: 14px;
  }
  .cs-font_16_sm {
    font-size: 16px;
  }

  .cs-font_18_sm {
    font-size: 18px;
  }

  .cs-font_20_sm {
    font-size: 20px;
  }

  .cs-font_22_sm {
    font-size: 22px;
  }

  .cs-font_24_sm {
    font-size: 24px;
  }

  .cs-font_26_sm {
    font-size: 26px;
  }

  .cs-font_28_sm {
    font-size: 28px;
  }

  .cs-font_42_sm {
    font-size: 42px;
  }

  .cs-font_36_sm {
    font-size: 36px;
  }
  .cs-btn_group > *:not(:last-child) {
    margin-right: 10px;
  }
  .flex-column-reverse-lg {
    flex-direction: column-reverse;
  }
  .cs-seciton_heading.cs-style1 .cs-section_title::before {
    top: 1px;
  }

  .landingWrap {
    .landRight {
      .spiral{
        left: 60%;
        height: 155%;
      }
    }
  }
}


@media screen and (max-width: 1199px) and (min-width: 992px) {
  .cs-right_space_40 {
    padding-right: 40px;
  }
  .heading{
    width: 455px;
  }
  .careearFlex {
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }
  .landingWrap {
    .landRight {
      .spiral{
        left: 60%;
        height: 180%;
      }
    }
  }
}

@media screen and (max-width: 1398px) and (min-width: 1201px) {
  .landingWrap {
    .landRight {
      .spiral{
        left: 60%;
        height: 186%;
      }
    }
  }
}


@media screen and (max-width: 991px) {

  .landingWrap {
    .landRight {
      .roundTube{
        height:100%;
      }
    }
  }
  .cs-bottom_footer {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .landingWrap h1 {
    font-size: 30px;
  }
  .sectionTwoGrid {
    grid-template-columns: 1fr;
  }
  .imageGrid {
    grid-template-columns: 1fr 1fr;
  }
  .joinPoints {
    margin-top: 1rem;
  }

  .companyLogo {
    .CGLogo {
      width: 10%;
    }
    .CGNameCircle {
      width: 20%;
    }
  }
  .careearFlex {
    margin: auto;
    grid-template-columns: 1fr 1fr;
  }


}

@media (max-width: 540px) and (min-width: 250px) {
  h2 {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .mb-sm-4 {
    margin-bottom: 20px;
  }

  .pt4-sm-4 {
    padding-top: 20px;
  }

  .mhide {
    display: none;
  }

  .center-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-image img {
    object-fit: contain;
  }
  .cardWidth {
    width: 100%;
  }
  .teamPoints {
    width: 100%;
    p {
      text-align: center;
    }
  }

  .heading{
    width: 100%;
  }
  .careearFlex {
    margin: auto;
    grid-template-columns: 1fr;
  }
  .mobileSection {
    h3 {
      margin-top: 1.5rem;
      text-align: center;
    }
  }
  .joinPoints {
    margin-top: 2rem;
  }

  .sectionPoints {
    padding: 0;
  }

  .imageGrid {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
  }

  .whatDoCenter {
    text-align: center;
  }

  .textservice-center {
    text-align: center;
  }
  .logowidth {
    width: 165px;
  }
  .servimg {
    width: 392px;
    height: auto !important;
  }

  h4 {
    font-size: 16px;
  }

  .boredr img {
    height: auto;
  }

  .margin-left-serviceimage {
    margin-top: 20px;
  }

  .landingWrap h1 {
    font-size: 30px;
  }
  .sectionTwoGrid {
    grid-template-columns: 1fr;
  }
  .imageGrid {
    grid-template-columns: 1fr 1fr;
  }
  .joinPoints {
    margin-top: 1rem;
  }
  .landingWrap {
    grid-template-columns: 1fr;
  }

  .teamimg {
    width: 550px;
  }

  .aboutimg {
    width: 33%;
  }

  .custom-margin-left {
    margin-left: 20px;
    margin-right: 20px;
  }

  .companyLogo {
    .CGLogo {
      width: 20%;
    }
    .CGNameCircle {
      width: 40%;
    }
  }
  .landingWrap {
    .landRight {
      .roundTube {
        display: none;
      }
    }
  }

  .landingWrap {
    .landRight {
      .spiral {
        position: absolute;
        top: -17%;
        left: 15%;
        width: 100%;
        height: 162%;
        z-index: 0;
      }
    }
  }

  .portfolioImgBg {
    .roundBg {
      img {
        width: 68%;
      }
    }
  }

}

.serviceimg {
  width: 389px;
  height: 242px;
}

.textunderline {
  text-decoration: underline;
}

.sticky-scroll-reveal {
  position: sticky;
  top: 0;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.9s ease-out, transform 0.9s ease-out;
}

.sticky-scroll-reveal.revealed {
  opacity: 1;
  transform: translateY(0);
}
.scroll-beam {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.scroll-beam .box-img {
  transition: transform 0.3s ease-in-out;
}

/* Optional: Style for scrollbar (for webkit browsers) */
.scroll-beam::-webkit-scrollbar {
  height: 8px;
}
