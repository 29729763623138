@import "../scss/default/color_variable";
.cs-fooer_main {
  padding: 60px 0 60px;
}

.cs-footer_item {
  margin-bottom: 40px;
  color: #aaa;
  .cs-newsletter.cs-style1 {
    margin-top: 6px;
  }
}

.cs-widget_title {
  font-size: 20px;
  margin-bottom: 20px;
}

.cs-menu_widget.cs-style1 {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    display: flex;
    grid-gap: 1rem;
    align-items: start;
    span {
      font-size: 24px;
      flex: none;
      width: 35px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
.cs-menu_widget.cs-style2 {
  display: flex;
  flex-wrap: wrap;
  li {
    &:not(:last-child) {
      &::after {
        content: "|";
        margin: 0 10px;
        position: relative;
        top: -1px;
      }
    }
  }
}

.cs-text_widget {
  p {
    margin: 0;
  }
  img + p {
    margin-top: 30px;
  }
}

.cs-social_btns.cs-style1 {
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
  a {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid $white;
    margin: 7px;
    color: $white;
    &:hover {
      border-color: $accent;
      transform: scale(1.08);
      svg {
        fill: $accent;
      }
    }
  }
}

.cs-bottom_footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid $border;
  > * {
    padding: 4px 0;
  }
}
.contactIcon {
  img {
    width: 20px;
    height: 20px;
    margin-top: 0.3rem;
  }
}

.ballLightFooter {
  position: relative;
  background-color: #010101;
  &::after {
    content: "";
    position: absolute;
    left: 45%;
    bottom: 4%;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-image: linear-gradient(#ffe588, #6a8aff);
    opacity: 1;
    filter: blur(88px);
  }
}
.logoFooterWidth {
  width: 200px;
}
.socialLink {
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: inline-flex;
  grid-gap: 1rem;
  align-items: center;
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  line-height: 1.3;
  margin: 2rem 0;
  width: 160px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 1px;
    height: 65%;
    left: 52px;
    background-color: #fff;
    margin: 0.5rem 0;
  }
  &:hover{
    background-color: #0077B5;
    color: #fff;
    border: 1px solid #0077B5;
  }
}

